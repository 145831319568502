<template lang="pug">
div
    loader(v-if="$root.loader")
    template(v-else)
        header-component
            v-btn.no-margin(absolute dark fab top right color='primary' @click.stop="drawerRight = !drawerRight")
                v-icon search
        v-navigation-drawer.filters.grey.darken-3(fixed v-model="drawerRight" right app width="246")
            v-form
                v-toolbar.grey.darken-1(flat)
                    v-text-field(v-model="filter.query" :placeholder="$t('Buscar...')" dark)
                    v-icon(dark) search
                .filters-content
                    .white--text.mb-2 {{$t('Filtrar por')}}:
                    v-dialog.mb-3(ref='dialogStart' v-model='modalDateStart' lazy full-width width='290px' :return-value.sync='filter.from')
                        v-text-field(slot='activator' :label="$t('Desde')" v-model='filter.from' readonly dark color="white")
                        v-date-picker(:locale="$root.currentLanguage" v-model='filter.from' scrollable)
                            v-spacer
                            v-btn(flat color='primary' @click.stop='modalDateStart = false') {{ $t('Cancelar') }}
                            v-btn(flat color='primary' @click.stop='$refs.dialogStart.save(filter.from)') {{ $t('OK') }}
                    v-dialog.mb-4(ref='dialogEnd' v-model='modalDateEnd' lazy full-width width='290px' :return-value.sync='filter.to')
                        v-text-field(slot='activator' :label="$t('Hasta')" v-model='filter.to' readonly dark color="white")
                        v-date-picker(:locale="$root.currentLanguage" v-model='filter.to' scrollable)
                            v-spacer
                            v-btn(flat color='primary' @click.stop='modalDateEnd = false') {{ $t('Cancelar') }}
                            v-btn(flat color='primary' @click.stop='$refs.dialogEnd.save(filter.to)') {{ $t('OK') }}
                    v-select.mb-3(
                        v-model="filter.state"
                        :items='filterState'
                        item-text="text"
                        item-value="key"
                        :label='$t("Estado")'
                        append-icon="keyboard_arrow_down" 
                        autocomplete
                        :filter="vFilter"
                        dark
                        color="white"
                    )
                    .btns.btns-right
                        v-btn(@click="cleanFilter" color="primary") {{ $t('Limpiar') }}
        v-content
            v-snackbar(
            :timeout="3000",
            :top="true"
            :right="true"
            :color="standColor"
            v-model="standMsj")
                | {{ standText }}
                v-btn(flat, dark, @click.native="standMsj = false") {{ $t('cerrar') }}
            v-container.grid-list-xl(fluid)
                h1 {{ $t('Casas de registro') }}
                template(v-if="preload")
                    h3 {{ $t('Cargando...') }}
                template(v-else)
                    v-layout(row wrap v-if="stands.length")
                        v-flex(xs12 sm6 md4 lg3 xl2 v-for="stand in stands" :key="stand.uuid")
                            .sheet.grey.lighten-3
                                .sheet-options
                                    v-menu(left)
                                        v-icon(color='primary' dark slot='activator') more_vert
                                        v-list.sheet-menu(v-if="(!stand.admin && stand.authorid == profile.uuid) || (isEmail && stand.admin)")
                                            v-list-tile.white--text(@click="$refs.manageStand.open('edit', stand, isEmail)")
                                                v-list-tile-title {{ $t('Editar') }}
                                            v-list-tile.white--text(@click="$refs.delete.open(stand)")
                                                v-list-tile-title {{ $t('Eliminar') }}
                                        v-list.sheet-menu(v-else-if="(isEmail && !stand.admin && stand.authorid != profile.uuid)")
                                            v-list-tile.white--text(@click="$refs.detailStand.open(stand)")
                                                v-list-tile-title {{ $t('Ver detalle') }}
                                            v-list-tile.white--text(v-if="stand.state == 2" @click="$refs.updateState.open({stand: stand, state: 1})")
                                                v-list-tile-title {{ $t('Aprobar') }}
                                            v-list-tile.white--text(v-if="stand.state == 2" @click="$refs.updateState.open({stand: stand, state: 0})")
                                                v-list-tile-title {{ $t('Rechazar') }}
                                            v-list-tile.white--text(v-if="stand.state == 1" @click="$refs.updateState.open({stand: stand, state: 0})")
                                                v-list-tile-title {{ $t('Rechazar') }}
                                            v-list-tile.white--text(v-if="stand.state == 0" @click="$refs.updateState.open({stand: stand, state: 1})")
                                                v-list-tile-title {{ $t('Aprobar') }}
                                        v-list.sheet-menu(v-else)
                                            v-list-tile.white--text(@click="$refs.detailStand.open(stand)")
                                                v-list-tile-title {{ $t('Ver detalle') }}
                                .sheet-title
                                    h2.primary--text {{ stand.title }}
                                .sheet-content
                                    p {{ stand.address }}
                                    b: small(v-if="!stand.admin && stand.authorid == profile.uuid") {{ stateLabel(stand.state) }}
                        v-flex(xs12)
                            infinite-loading(:infinite="infinite" ref="infinite")
                    .not-found(v-else)
                        h3 {{ $t('No se encontraron casas de registro') }}
                v-btn(@click.native="$refs.manageStand.open('add', {}, isEmail)" fixed dark fab bottom right color='primary')
                    v-icon add
        confirm(ref="delete" @action="deleteStand" :title="$t('Eliminar casa de registro')" :message="$t('¿Estás seguro de eliminar esta casa de registro?')")
        confirm(ref="updateState" @action="updateState" :title="$t('Actualizar estado de la casa de registro')" :message="$t('¿Estás seguro de actualizar el estado de esta casa de registro?')")
        manage-stand(ref="manageStand" @success="fetchStand")
        detail-stand(ref="detailStand")
</template>

<script>
import auth from 'mixins/auth'
import ManageStand from 'components/stand/Manage'
import DetailStand from 'components/stand/Detail'
import fn from 'mixins/fn'

export default {
    mixins: [auth, fn],
    metaInfo() {
        return {
            title: this.$t('Casas de registro')
        }
    },
    data() {
        return {
            preload: false,
            drawerRight: false,
            modalDateStart: false,
            modalDateEnd: false,
            filter: {},
            filterSchema: {
                query: '',
                from : null,
                to   : null,
                state: ''
            },
            filterState: [
                {
                    key: 0,
                    text: 'Rechazado'
                },
                {
                    key: 1,
                    text: 'Aprobado'
                },
                {
                    key: 2,
                    text: 'Pendiente'
                }
            ],
            stands : [],
            limit : this.$root.limit,
            offset: 0,
            standMsj  : false,
            standColor: '',
            standText : '',
        }
    },
    watch: {
        filter: {
            handler() {
                this.fetchStand()
            },
            deep: true
        }
    },
    computed: {
        isEmail() {
            return this.$root.profile.network != undefined && 'email' in this.$root.profile.network && this.$root.profile.network.email.indexOf(this.$root.profile.email) != -1
        },
    },
    created(){

        this.fetchStand()
        this.filter = Object.assign({}, this.filterSchema)
    },
    methods: {
        fetchStand(infinite = false) {
            
            if(infinite){
                this.offset += this.limit
            }else{
                this.preload = true
                this.stands = []
                this.offset = 0
                this.$refs.infinite && this.$refs.infinite.reset()
            }

            let filter = {
                limit : this.limit,
                offset: this.offset,
                filter: JSON.stringify(this.filter)
            }

            this.$api(this, (xhr) => {
                xhr.get('/stand', { params: filter }).then((r) => {

                    if(!infinite) this.preload = false
                    let data = r.data

                    if(infinite){
                        if(data.response){
                            this.stands = this.stands.concat(data.data)
                            this.$refs.infinite && this.$refs.infinite.loaded()
                        }else{
                            this.$refs.infinite && this.$refs.infinite.complete()
                        }
                    }else{
                        this.stands = (data.response ? data.data : [])
                    }
                }).catch(() => {})
            })
        },
        infinite(){
            setTimeout(() => this.fetchStand(true) , 500)
        },
        deleteStand({uuid}){

            this.$refs.delete.$emit('playLoader')
            this.$api(this, (xhr) => {
                xhr.delete('/stand?uuid=' + uuid).then((r) => {
                    
                    this.$refs.delete.$emit('stopLoader')
                    this.standMsj = true

                    let data = r.data

                    if(data.response){
                        this.standColor = 'success'
                        this.standText = data.message
                        this.$refs.delete.close()
                        this.fetchStand()
                    }else{
                        this.standColor = 'error'
                        this.standText = data.message
                    }
                }).catch(() => {})
            })
        },
        cleanFilter(){
            this.filter = Object.assign({}, this.filterSchema)
        },
        stateLabel(state) {
            let stateLabel = this.filterState.find(s => s.key == state)
            if (stateLabel) {
                return stateLabel.text
            }

            return ''
        },
        updateState({stand, state}){

            this.$refs.updateState.$emit('playLoader')
            this.$api(this, (xhr) => {
                xhr.post('/stand/state', this.$qs.stringify({
                    uuid: stand.uuid,
                    state
                })).then((r) => {
                    
                    this.$refs.updateState.$emit('stopLoader')
                    this.standMsj = true

                    let data = r.data

                    if(data.response){
                        this.standColor = 'success'
                        this.standText = data.message
                        this.$refs.updateState.close()
                        this.fetchStand()
                    }else{
                        this.standColor = 'error'
                        this.standText = data.message
                    }
                }).catch(() => {})
            })
        }
    },
    components: {
        ManageStand,
        DetailStand
    }
}
</script>